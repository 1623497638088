import { ReactNode, useCallback, useContext, useEffect } from 'react';
import { getProductBySku, getRunConfigOptionsBySku } from '@/services/product.service';
import { MarketRunConfigOptions } from '@/types/RunConfigOptions';
import { NoWrap } from '@/components/NoWrap';
import { RunConfigMarket } from '@/types/ReportRun';
import { intersection, noop, uniq, uniqBy, isArray } from 'lodash';
import { useUser } from '@/contexts/UserContext';
import { useMarketContext } from '@/contexts/MarketContext';
import { useCategoryService } from './category.service';
import { useTenantApi } from '@/hooks/useTenantApi';
import { RootMarketNodes } from '@/types/ApiTypes';
import { MrktEntitlementTypeMessages } from '@/utils/MarketEntitlementMessages';
import { MrktInfoIconCopy } from '@/utils/MarketEntitlementMessages';
import { MarketPickerContext } from '@/components/MarketPicker';

const TOTAL_US = 'Total US';
const BY_CHANNEL_FMCG = 'By Channel FMCG';
const BY_CHANNEL_SPECIALITY = 'By Channel Speciality';
const BY_GEOGRAPHY = 'By Geography';
const PANEL_TOTAL_US_MARKETS = 'Panel Total US Markets';
const PANEL_BY_CHANNEL = 'Panel By Channel';
const SELECT_BY_CHANNEL = 'Select By Channel';

export type GetMarketNodeOptions = {
    categories: string[];
};

export type GetChildMarketNodesParams = {
    sku: string;
    categories: string[];
    runType?: RunType;
    parent: MarketNode;
    purchasedMarketKeys?: string[];
    isDodMarketPicker: boolean;
    hasSummedCategories?: boolean;
    summedCategories?: string[];
    nonSummedCategories?: string[];
    isMarketSummedMode?:boolean;
    isEnableParentCompanyMode?: boolean;
};

export type PriorApprovalProps = {
    hasNoApproval: boolean;
    hasApproval: boolean;
    hasPartialApproval: boolean;
};

export type GetStoriesChildMarketNodesParams = {
    categories?: string[];
    runType?: RunType;
    parent: MarketNode;
    marketPickerConfig: any;
    purchasedMarketKeys?: string[];
}

export type FindMatchingMarketOptionsParams = {
    searchText: string;
    runType: RunType;
    sku: string;
    categories?: string[];
    subMarketType?: string;
    purchasedMarketKeys?: string[];
};

export type GetRootMarketNodesParams = {
    sku: string;
    runType: RunType;
    categories: string[];
    purchasedMarketKeys?: string[];
    isDodMarketPicker: boolean;
    hasSummedCategories?: boolean;
    summedCategories?: string[];
    nonSummedCategories?: string[];
    isMarketSummedMode?:boolean,
};

export type GetStoriesRootMarketNodesParams = {
    runType: RunType;
    categories?: string[];
    marketPickerConfig: any;
    purchasedMarketKeys?: string[];
}

export type GetAccessibleMarketsParams = {
    sku: string;
    runType: RunType;
    categories: string[];
    purchasedMarketKeys?: string[];
};

export type GetAllMarketsParams = {
    searchTerm?: string;
    categories: string[];
    includeFmcgRetailers?: boolean;
    includeSpecialityRetailers?: boolean;
    includeGeographyMarkets?: boolean;
    includeTotalUSMarkets?: boolean;
    includePanelTotal?: boolean;
    includePannelByChannel?: boolean;
    includeRemainingMarkets?: boolean;
    purchasedMarketKeys?:string[];
    isDodMarketPicker:boolean;
    hasSummedCategories?:boolean;
    summedCategories?:string[];
    nonSummedCategories?:string[];
    isMarketSummedMode?:boolean;
    shouldUseAccessibleMarkets?: boolean;
};

export type FilterMarketOptionsParams = {
    searchTerm?: string;
    categories: string[];
    includeFmcgRetailers?: boolean;
    includeSpecialityRetailers?: boolean;
    includeGeographyMarkets?: boolean;
    includeTotalUSMarkets?: boolean;
    includePanelTotal?: boolean;
    includePannelByChannel?: boolean;
    marketType?: string;
    marketLevel?: string;
    marketTier?: string;
    parentCompanies?: string[];
    channel?: string;
    childMarket?: string;
    purchasedMarketKeys?:string[];
    isDodMarketPicker:boolean;
    hasSummedCategories?:boolean;
    summedCategories?:string[];
    nonSummedCategories?:string[];
    isMarketSummedMode?:boolean,
    childMarketType?: string;

};

export type GetMarketOptionsForDodRunParams = {
    categories: string[];
    includeTotalUSMarkets?: boolean;
    includeFmcgRetailers?: boolean;
    includeSpecialityRetailers?: boolean;
    includeGeographyMarkets?: boolean;
    includePanelTotal?: boolean;
    includePanelChannel?: boolean;
};

// this should really be a context, but I'm too tired to figure out the proper implementation
let selectableMarketCache: Record<string, MarketNode> = {};

export type GetMarketsOptionsByMarketFilterTypeParams = {
    categories: string[];
    marketType: string;
    purchasedMarketKeys?:string[];
    isDodMarketPicker:boolean;
    hasSummedCategories?:boolean;
    summedCategories?:string[];
    nonSummedCategories?:string[];
    isMarketSummedMode?:boolean,
};

export type AddPriorApprovalCoverageParams = {
    isDodMarket:boolean,
    categories: string[],
    isCategoriesSummedMode?: boolean|undefined,
    summedCategories?: string[] | undefined,
    nonSummedCategories?: string[] | undefined,
    isMarketSummedMode?:boolean,
}

export function useMarketService() {
    const { getAccessibleMarkets, searchMarkets, getRootMarketNodes: getRootMarketNodesApi } = useTenantApi();

    const { allMarkets } = useMarketContext();
    const { company, accessibleMasterCompanies,features: { enableLimitedMarketSubscription }
     } = useUser();
     const { requiredMasterCompany, requiredMarketGroup, requireRemainingMarket } = useContext(MarketPickerContext);
    const { getCategoryByKey, filterSubscribedCategories } = useCategoryService();

    async function getMarketsOptionsByMarketFilterType({
        categories: productCategories,
        marketType,
        purchasedMarketKeys,
        isDodMarketPicker,
        hasSummedCategories,
        summedCategories,
        nonSummedCategories,
        isMarketSummedMode,
    }: GetMarketsOptionsByMarketFilterTypeParams): Promise<MarketOption[]> {
        let rawMarkets: MarketNode[] = [];
        rawMarkets = allMarkets.filter((mrkt) => mrkt.subMrktType === marketType);
        // rawMarkets = await api.marketSearchByMrktTypeForDodRun({
        //     categories: categories,
        //     marketType: marketType,
        //     reportType: "subscription"
        // });
        const categories = isDodMarketPicker ? filterSubscribedCategories(productCategories, true) as string[] : productCategories
        return rawMarkets
            .map(addCategoryCoverage(categories))
            .map(addRevShareCoverage(purchasedMarketKeys))
            .map(
                addPriorApprovalCoverage({
                    isDodMarket: isDodMarketPicker,
                    categories: categories,
                    isCategoriesSummedMode: hasSummedCategories,
                    summedCategories,
                    nonSummedCategories,
                    isMarketSummedMode,
                })
            )
            .map((market) => ({
                type: market.type,
                display: market.name,
                value: market.path,
                data: market,
            }));
    }

    async function getAccessibleRootMarketNodes({
        sku,
        categories,
        runType = 'subscription',
    }: GetAccessibleMarketsParams): Promise<RootMarketNodes> {
        const config = getReportMarketConfig(sku);

        const rawMarkets = await getAccessibleMarkets({
            categories,
            runType,
            ...config,
        });
        // const rawMarkets = allMarkets.filter(market => accessibleMasterCompanies.includes(market.masterCompany!));

        return {
            accessible: rawMarkets,
        };
    }
    /** this method is used for searching markets in advance search/any search in DOD */
    function filterMarketOptions({
        searchTerm,
        categories: productCategories,
        includeFmcgRetailers = true,
        includeGeographyMarkets = true,
        includeSpecialityRetailers = true,
        includeTotalUSMarkets = true,
        includePanelTotal = true,
        includePannelByChannel = true,
        marketType,
        marketLevel,
        marketTier,
        parentCompanies,
        channel,
        purchasedMarketKeys,
        isDodMarketPicker,
        hasSummedCategories,
        summedCategories,
        nonSummedCategories,
        isMarketSummedMode,
        childMarketType
    }: FilterMarketOptionsParams): MarketNode[] {
        let matchingMarkets = allMarkets;
        const categories = isDodMarketPicker ? filterSubscribedCategories(productCategories, true) as string[] : productCategories

        if (searchTerm) {
            matchingMarkets = matchingMarkets.filter(
                (market) => market.name.toLowerCase().includes(searchTerm.toLowerCase()) && market.selectable
            );
        }
        if (!includeFmcgRetailers) {
            matchingMarkets = matchingMarkets.filter((market) => market.selectionType !== BY_CHANNEL_FMCG);
        }
        if (!includeGeographyMarkets) {
            matchingMarkets = matchingMarkets.filter((market) => market.selectionType !== BY_GEOGRAPHY);
        }
        if (!includeSpecialityRetailers) {
            matchingMarkets = matchingMarkets.filter((market) => market.selectionType !== BY_CHANNEL_SPECIALITY);
        }
        if (!includeTotalUSMarkets) {
            matchingMarkets = matchingMarkets.filter((market) => market.selectionType !== TOTAL_US);
        }
        if (!includePanelTotal) {
            matchingMarkets = matchingMarkets.filter((market) => market.selectionType !== PANEL_TOTAL_US_MARKETS);
        }
        if (!includePannelByChannel) {
            matchingMarkets = matchingMarkets.filter((market) => market.selectionType !== PANEL_BY_CHANNEL);
        }

        if (marketType) {
            matchingMarkets = matchingMarkets.filter(
                (market) => market.subMrktType?.toLowerCase() === marketType.toLowerCase()
            );
        }
        if (marketLevel) {
            if (marketLevel === 'parent') {
                matchingMarkets = matchingMarkets.filter(
                    (market) => market.subMrktType === 'Account' && market.marketLevel === 'Level 1'
                );
            }
            if (marketLevel === 'child') {
                matchingMarkets = matchingMarkets.filter(
                    (market) => market.subMrktType === 'Account' && market.marketLevel !== 'Level 1'
                );
            }
        }

        if (marketTier) {
            matchingMarkets = matchingMarkets.filter((market) =>
                market.marketTier?.toLowerCase().includes(marketTier.toLowerCase())
            );
        }

        if (parentCompanies?.length) {
            matchingMarkets = matchingMarkets.filter((market) =>
                market.masterCompany ? parentCompanies.includes(market?.masterCompany) : noop
            );
        }

        if (channel) {
            matchingMarkets = matchingMarkets.filter(
                (market) => market.channel?.toLowerCase() === channel.toLowerCase()
            );
        }
        if (childMarketType) {
            matchingMarkets = matchingMarkets.filter(
                (market) => market.subFolder?.toLowerCase() === childMarketType.toLowerCase()
            );
        }
        return matchingMarkets
            .map(addCategoryCoverage(categories))
            .map(addRevShareCoverage(purchasedMarketKeys))
            .map(addPriorApprovalCoverage({
                isDodMarket: isDodMarketPicker,
                categories,
                isCategoriesSummedMode: hasSummedCategories,
                summedCategories,
                nonSummedCategories,
                isMarketSummedMode,
            }));
    }

    function getMarketTypes(): string[] {
        const marketTypes: Set<string> = new Set();
        allMarkets.forEach((market) => {
            if (
                market.selectable &&
                [TOTAL_US, BY_GEOGRAPHY, BY_CHANNEL_FMCG, BY_CHANNEL_SPECIALITY].includes(market.selectionType)
            ) {
                if (market?.subMrktType) marketTypes.add(market.subMrktType);
            }
        });
        return Array.from(marketTypes).sort();
    }

    function getMarketChannels(): string[] {
        const marketChannels: Set<string> = new Set();
        allMarkets.forEach((market) => {
            if (
                market.selectable &&
                [TOTAL_US, BY_GEOGRAPHY, BY_CHANNEL_FMCG, BY_CHANNEL_SPECIALITY].includes(market.selectionType)
            ) {
                if (market?.channel) marketChannels.add(market?.channel);
            }
        });
        return Array.from(marketChannels).sort();
    }

    function getChildMarketTypes(): string[] {
        const childMarketTypes: Set<string> = new Set();
        allMarkets.forEach((market) => {
            if (
                market?.subFolder &&
                [TOTAL_US, BY_GEOGRAPHY, BY_CHANNEL_FMCG, BY_CHANNEL_SPECIALITY].includes(market.selectionType)
            ) {
                childMarketTypes.add(market?.subFolder?.trim());
            }
        });
        return Array.from(childMarketTypes).sort();
    }

    function getMarketParentCompanies(): string[] {
        const parentCompanies: Set<string> = new Set();
        allMarkets.forEach((market) => {
            if (
                market.selectable &&
                [TOTAL_US, BY_GEOGRAPHY, BY_CHANNEL_FMCG, BY_CHANNEL_SPECIALITY].includes(market.selectionType)
            ) {
                if (market?.masterCompany) parentCompanies.add(market?.masterCompany);
            }
        });
        return Array.from(parentCompanies).sort();
    }

    function getAllRunConfigMarkets(params: GetAllMarketsParams): RunConfigMarket[] {

        return getAllMarkets(params).map((node)=>marketNodeToRunConfigMarket(node));
    }

    // this is specific to DOD fornow
    function getAllMarkets({
        categories,
        includeFmcgRetailers = true,
        includeGeographyMarkets = true,
        includeSpecialityRetailers = true,
        includeTotalUSMarkets = true,
        includePanelTotal = true,
        includePannelByChannel = true,
        includeRemainingMarkets = true,
        searchTerm,
        purchasedMarketKeys,
        isDodMarketPicker,
        hasSummedCategories,
        summedCategories,
        nonSummedCategories,
        isMarketSummedMode,
        shouldUseAccessibleMarkets = false
    }: GetAllMarketsParams): MarketNode[] {
        let matchingMarkets =  allMarkets;
        if(shouldUseAccessibleMarkets) {
          matchingMarkets = allMarkets.filter(market => accessibleMasterCompanies.includes(market.masterCompany!));
        }


        if (searchTerm) {
            matchingMarkets = matchingMarkets.filter(
                (market) => market.name.toLowerCase().includes(searchTerm.toLowerCase()) && market.selectable
            );
        }
        if (!includeRemainingMarkets) {
            matchingMarkets = matchingMarkets.filter((market) => !market.isRemaining);
        }
        if (!includeFmcgRetailers) {
            matchingMarkets = matchingMarkets.filter((market) => market.selectionType !== BY_CHANNEL_FMCG);
        }
        if (!includeGeographyMarkets) {
            matchingMarkets = matchingMarkets.filter((market) => market.selectionType !== BY_GEOGRAPHY);
        }
        if (!includeSpecialityRetailers) {
            matchingMarkets = matchingMarkets.filter((market) => market.selectionType !== BY_CHANNEL_SPECIALITY);
        }
        if (!includeTotalUSMarkets) {
            matchingMarkets = matchingMarkets.filter((market) => market.selectionType !== TOTAL_US);
        }
        if (!includePanelTotal) {
            matchingMarkets = matchingMarkets.filter((market) => market.selectionType !== PANEL_TOTAL_US_MARKETS);
        }
        if (!includePannelByChannel) {
            matchingMarkets = matchingMarkets.filter((market) => market.selectionType !== PANEL_BY_CHANNEL);
        }

        return matchingMarkets
            .map(addCategoryCoverage(categories))
            .map(addRevShareCoverage(purchasedMarketKeys))
            .map(
                addPriorApprovalCoverage({
                    isDodMarket: isDodMarketPicker,
                    categories,
                    isCategoriesSummedMode: hasSummedCategories,
                    summedCategories,
                    nonSummedCategories,
                    isMarketSummedMode,
                })
            );
    }

    const findMatchingMarketOptions = useCallback(
        async ({
            sku,
            categories,
            subMarketType,
            runType,
            searchText,
            purchasedMarketKeys,
        }: FindMatchingMarketOptionsParams): Promise<MarketOption[]> => {
            const config = getReportMarketConfig(sku);

            const rawMarkets = await searchMarkets({
                searchText,
                categories,
                subMarketType,
                runType,
                ...config,
                includeTotalUSMarkets: config?.includeTotalUSMarkets === true && config?.hideTotalUSMarkets !== true,
            });

            return rawMarkets.map((market) => ({
                type: market.type,
                display: market.name,
                value: market.path,
                data: market,
            }));
        },
        []
    );

    const getRootMarketNodes = useCallback(
        async ({
            sku,
            categories: productCategories,
            runType = 'subscription',
            purchasedMarketKeys,
            isDodMarketPicker,
            hasSummedCategories,
            summedCategories,
            nonSummedCategories,
            isMarketSummedMode,
        }: GetRootMarketNodesParams): Promise<RootMarketNodes> => {
            const config = getReportMarketConfig(sku);
            const categories = isDodMarketPicker ? filterSubscribedCategories(productCategories, true) as string[] : productCategories

            const geographies = allMarkets.filter(
                (market) => 
                    config.includeGeographyMarkets &&
                    market.selectionType === BY_GEOGRAPHY && market.level === 1 && market.isRemaining === false
            );
            const totalUs = allMarkets.filter(
                (market) => 
                    config.includeTotalUSMarkets &&
                    market.selectionType === TOTAL_US && market.level === 1 && market.isRemaining === false
            );
            const fmcgRetailers = allMarkets.filter(
                (market) =>
                    config.includeFmcgRetailers &&
                    market.selectionType === BY_CHANNEL_FMCG && market.level === 1 && market.isRemaining === false
            );
            const specialityRetailers = allMarkets.filter(
                (market) =>
                    config.includeSpecialityRetailers &&
                    market.selectionType === BY_CHANNEL_SPECIALITY && market.level === 1 && market.isRemaining === false
            );
            const panelChannels = allMarkets.filter(
                (market) =>
                    config.includePanelChannel &&
                    market.selectionType === PANEL_BY_CHANNEL && market.level === 1 && market.isRemaining === false
            );
            const panelTotal = allMarkets.filter(
                (market) =>
                    config?.includePanelTotal && 
                    market.selectionType === PANEL_TOTAL_US_MARKETS &&
                    market.level === 1 &&
                    market.isRemaining === false
            );

            return {
                geographies: geographies
                    .map(addCategoryCoverage(categories))
                    .map(addRevShareCoverage(purchasedMarketKeys))
                    .map(
                        addPriorApprovalCoverage({
                            isDodMarket: isDodMarketPicker,
                            categories,
                            isCategoriesSummedMode: hasSummedCategories,
                            summedCategories,
                            nonSummedCategories,
                            isMarketSummedMode,
                        })
                    ),
                specialityRetailers: specialityRetailers
                    .map(addCategoryCoverage(categories))
                    .map(addRevShareCoverage(purchasedMarketKeys))
                    .map(
                        addPriorApprovalCoverage({
                            isDodMarket: isDodMarketPicker,
                            categories,
                            isCategoriesSummedMode: hasSummedCategories,
                            summedCategories,
                            nonSummedCategories,
                            isMarketSummedMode,
                        })
                    ),
                fmcgRetailers: fmcgRetailers
                    .map(addCategoryCoverage(categories))
                    .map(addRevShareCoverage(purchasedMarketKeys))
                    .map(
                        addPriorApprovalCoverage({
                            isDodMarket: isDodMarketPicker,
                            categories,
                            isCategoriesSummedMode: hasSummedCategories,
                            summedCategories,
                            nonSummedCategories,
                            isMarketSummedMode,
                        })
                    ),
                totalUs: totalUs
                    .map(addCategoryCoverage(categories))
                    .map(addRevShareCoverage(purchasedMarketKeys))
                    .map(
                        addPriorApprovalCoverage({
                            isDodMarket: isDodMarketPicker,
                            categories,
                            isCategoriesSummedMode: hasSummedCategories,
                            summedCategories,
                            nonSummedCategories,
                            isMarketSummedMode,
                        })
                    ),
                panelChannels: panelChannels,
                panelTotal: panelTotal,
            };
        },
        []
    );

    const getChildMarketNodes = useCallback(
        async ({
            parent,
            sku,
            categories: productCategories,
            runType = 'subscription',
            purchasedMarketKeys,
            isDodMarketPicker,
            hasSummedCategories,
            summedCategories,
            nonSummedCategories,
            isMarketSummedMode,
            isEnableParentCompanyMode = false
        }: GetChildMarketNodesParams): Promise<MarketNode[]> => {
            const categories = isDodMarketPicker ? filterSubscribedCategories(productCategories) as string[] : productCategories;

            let markets: MarketNode[] = [];

            if (isEnableParentCompanyMode) {
                // If it is isEnableParentCompanyMode then need to return pseudo markets or its outlets
                if (parent.isParentCompany) {
                    markets = allMarkets.filter(
                        (market) =>
                            market.isOutletOfParentCompany && market.selectionType === parent.selectionType && market.parentFolderPath === parent.parentFolderPath && parent.childPaths?.includes(market?.childRootPath!)
                    );
                } else {
                    markets = allMarkets.filter(
                        (market) =>
                            !market.isOutletOfParentCompany && market.selectionType === parent.selectionType && market.parentFolderPath === parent.childRootPath
                    );
                }
            } else {
                // avoiding pseudo/custom parents by checking market.isParentCompany == false
                markets = allMarkets.filter(
                    (market) =>
                        !market.isParentCompany && market.selectionType === parent.selectionType && market.parentFolderPath === parent.childRootPath
                );  
            }

            const panelMarkets: MarketNode[] = markets.filter(
                (market) => market.selectionType === PANEL_BY_CHANNEL || market.selectionType === PANEL_TOTAL_US_MARKETS
            );

            const nonPanelMarkets: MarketNode[] = markets
                .filter(
                    (market) =>
                        market.selectionType !== PANEL_BY_CHANNEL && market.selectionType !== PANEL_TOTAL_US_MARKETS
                )
                .map(addCategoryCoverage(categories))
                .map(addRevShareCoverage(purchasedMarketKeys))
                .map(
                    addPriorApprovalCoverage({
                        isDodMarket: isDodMarketPicker,
                        categories,
                        isCategoriesSummedMode: hasSummedCategories,
                        summedCategories,
                        nonSummedCategories,
                        isMarketSummedMode,
                    })
                );
            return [...panelMarkets, ...nonPanelMarkets];
        },
        []
    );

    //PMA Logic
    function addPriorApprovalCoverage(params: AddPriorApprovalCoverageParams): (market: MarketNode) => MarketNode {
        return (market) =>
            market.requiresApproval
                ? params.isDodMarket
                    ? applyDodPriorApprovals(
                          market,
                          params.categories,
                          params.isCategoriesSummedMode as boolean, //PR review candidate
                          params.summedCategories as string[],
                          params.nonSummedCategories as string[],
                          params.isMarketSummedMode as boolean,
                      )
                    : applyGenericPriorApprovals(market, params.categories)
                : { ...market };
    }

    function applyGenericPriorApprovals(market: MarketNode, categories: string[]): MarketNode {
        let companyPriorApprovals: string[] =
            company?.priorApprovals.map((priorApproval) => priorApproval.masterCompanyCode) ?? [];

        if (market.parentRetailerId) {
            if (companyPriorApprovals.includes(market?.parentRetailerId)) {
                const retailer = company?.priorApprovals.filter(
                    (priorApproval) => priorApproval.masterCompanyCode === market.parentRetailerId
                )[0];

                //if the prior_approved markets as All Categries set
                if (retailer?.categoryKeys.length === 0) {
                    return { ...market, hasNoApproval: false, hasApproval: true, hasPartialApproval: false };
                } else {
                    const retailerApprovedCategories = retailer?.categoryKeys?.map((key) => getCategoryByKey(key));
                    const intersectedCategoryCount = intersection(retailerApprovedCategories, categories).length;

                    if (!intersectedCategoryCount) {
                        return { ...market, hasNoApproval: true, hasApproval: false, hasPartialApproval: false };
                    }
                    if (
                        intersectedCategoryCount &&
                        categories.every((cat) => retailerApprovedCategories?.includes(cat))
                    ) {
                        return { ...market, hasNoApproval: false, hasApproval: true, hasPartialApproval: false };
                    } else {
                        return { ...market, hasNoApproval: true, hasApproval: false, hasPartialApproval: false };
                    }
                }
            } else {
                return { ...market, hasNoApproval: true, hasApproval: false, hasPartialApproval: false };
            }
        } else {
            return market;
        }
    }

    function applyDodPriorApprovals(
        market: MarketNode,
        categories: string[],
        inSummedMode: boolean,
        summedCategories?: string[],
        nonSummedCategories?: string[],
        inMarketsSummedMode?: boolean,
    ): MarketNode {
        let categoryPriorApproval = {
            hasApproval: false,
            hasPartialApproval: false,
            hasNoApproval: false,
        };
        let summedCategoryPriorApproval = {
            hasApproval: false,
            hasPartialApproval: false,
            hasNoApproval: false,
        };
        let marketPriorApprovals = { hasApproval: false, hasPartialApproval: false, hasNoApproval: false };

        const retailer = company?.priorApprovals.filter(
            (priorApproval) => priorApproval.masterCompanyCode === market.parentRetailerId
        )[0];
        const approvedCategoryKeys = retailer?.categoryKeys ?? [];
        const approvedCategoriesForMarket = approvedCategoryKeys.map(getCategoryByKey);

        if (!inSummedMode) {
            if (categories.length && approvedCategoryKeys.length) {
                categoryPriorApproval = applyPriorApprovalsForCategories(
                    categories,
                    approvedCategoriesForMarket,
                    inSummedMode
                );
            }
        }else if (inSummedMode && nonSummedCategories?.length === 0) {
            if (summedCategories?.length  && approvedCategoryKeys.length) {
                summedCategoryPriorApproval = applyPriorApprovalsForCategories(
                    summedCategories,
                    approvedCategoriesForMarket,
                    inSummedMode
                );
            }
        }else{
            categoryPriorApproval = applyPriorApprovalsForCategories(
                nonSummedCategories!,
                approvedCategoriesForMarket,
                inSummedMode
            );
            summedCategoryPriorApproval = applyPriorApprovalsForCategories(
                summedCategories as string[],
                approvedCategoriesForMarket,
                inSummedMode
            );
        }

        const noneApproved = [categoryPriorApproval, summedCategoryPriorApproval].filter(
            (info) => info.hasNoApproval === true
        );
        const allApproved = [categoryPriorApproval, summedCategoryPriorApproval].filter(
            (info) => info.hasApproval === true
        );
        const partiallyApproved = [categoryPriorApproval, summedCategoryPriorApproval].filter(
            (info) => info.hasPartialApproval === true
        );

        if (retailer && approvedCategoryKeys.length === 0) {
            marketPriorApprovals = {
                hasNoApproval: false,
                hasApproval: true,
                hasPartialApproval: false,
            };
        }else if (allApproved.length && !partiallyApproved.length && !noneApproved.length ) {
            marketPriorApprovals = {
                hasNoApproval: false,
                hasApproval: true,
                hasPartialApproval: false,
            };
        } else if (!allApproved.length && !partiallyApproved.length && noneApproved.length) {
            marketPriorApprovals = {
                hasNoApproval: true,
                hasApproval: false,
                hasPartialApproval: false,
            };
        } else if (!allApproved.length && partiallyApproved.length && !noneApproved.length) {
            marketPriorApprovals = {
                hasNoApproval: inSummedMode ? true : false,
                hasApproval: false,
                hasPartialApproval: inSummedMode ? false : true,
            };
        }else if( partiallyApproved.length || (summedCategoryPriorApproval.hasNoApproval && summedCategoryPriorApproval.hasPartialApproval)){
            // TODO: verify/discuss below logic for summed categories and partial approval
            // marketPriorApprovals = {
            //     hasPartialApproval: summedCategoryPriorApproval.hasPartialApproval ? true : false,
            //     hasApproval: false,
            //     hasNoApproval: summedCategoryPriorApproval.hasPartialApproval ? false : true,
            // };
            marketPriorApprovals = {
                hasNoApproval: summedCategoryPriorApproval.hasPartialApproval ? true:false,
                hasApproval: false,
                hasPartialApproval: summedCategoryPriorApproval.hasPartialApproval ? false:true,
            };
        }else if (
            (allApproved.length && (noneApproved.length || partiallyApproved.length)) ||
            (!allApproved.length && partiallyApproved.length && noneApproved.length)
        ) {
            marketPriorApprovals = {
                hasNoApproval: false,
                hasApproval: false,
                hasPartialApproval: true,
            };
        } else {
            marketPriorApprovals = {
                hasNoApproval: true,
                hasApproval: false,
                hasPartialApproval: false,
            };
        }
        
        if(inMarketsSummedMode && marketPriorApprovals.hasPartialApproval === true){                
            marketPriorApprovals = {
                hasNoApproval: true,
                hasApproval: false,
                hasPartialApproval: false,
            };
        }
              
        return { ...market, ...marketPriorApprovals };
    }

    function applyPriorApprovalsForCategories(
        categories: string[],
        approvedCategories: string[],
        inSummedMode,
    ): PriorApprovalProps {
        let categoryPriorApproval = {
            hasNoApproval: false,
            hasApproval: false,
            hasPartialApproval: false,
        };

        const intersectedCategoryCount = intersection(approvedCategories, categories).length;

        if (!intersectedCategoryCount) {
            categoryPriorApproval.hasNoApproval = true;
            categoryPriorApproval.hasApproval = false;
            categoryPriorApproval.hasPartialApproval = false;
        }else{
            if(intersectedCategoryCount &&
                categories.every((cat) => approvedCategories?.includes(cat)) ){
                categoryPriorApproval.hasNoApproval = false;
                categoryPriorApproval.hasApproval = true;
                categoryPriorApproval.hasPartialApproval = false;
            }else{
                categoryPriorApproval.hasNoApproval = inSummedMode ? true : false;
                categoryPriorApproval.hasApproval = false;
                categoryPriorApproval.hasPartialApproval = true;
            }
        }
        return categoryPriorApproval;
    }

    function getEnabledMarkets(markets: RunConfigMarket[] | undefined, categories: string[]) {
        return markets?.reduce((acc: RunConfigMarket[], item) => {
            const disabledMarketNode = isMarketDisabled(getCachedMarketNodeByName(item.name, categories)!, {
                requiredMarketGroup,
                requiredMasterCompany,
                requireRemainingMarket,
                enableLimitedMarketSubscription,
                accessibleMasterCompanies,
                purchasedMarketKeys: company?.purchasedMarketKeys,
            });
            if (!disabledMarketNode) {
                acc.push({ ...item as RunConfigMarket });
            }
            return acc;
        }, []);
    }

    function getCachedMarketNodeByName(
        name?: string,
        categories?: string[] | undefined,
        isDodMarketPicker?: boolean,
        hasSummedCategories?: boolean,
        summedCategories?: string[] | undefined,
        nonSummedCategories?: string[] | undefined,
        isMarketSummedMode?:boolean,
    ): MarketNode | undefined {

        const market = allMarkets.find(mrkt => mrkt.name === name);
        if (!market) return;

        let priorApprovalMrkt = addPriorApprovalCoverage({
            isDodMarket: isDodMarketPicker as boolean,
            categories: categories as string[],
            isCategoriesSummedMode: hasSummedCategories,
            summedCategories,
            nonSummedCategories,
            isMarketSummedMode,
        });
        return priorApprovalMrkt(market);
    }

    function getCachedMarketNodesByKeys(
        keys: (string | undefined | null)[] = [],
        priorApprovalParams?: AddPriorApprovalCoverageParams
    ): MarketNode[] {
        keys = keys.filter(Boolean);

        if (!keys.length) return [];

        const marketNodes = allMarkets.filter((market) => keys.includes(market.key!))
        if(!priorApprovalParams) {
            return marketNodes
        } else {
            return marketNodes
                .map(addCategoryCoverage(uniq([...priorApprovalParams.categories, ...(priorApprovalParams?.summedCategories || [])])))
                .map(addRevShareCoverage(company?.purchasedMarketKeys))
                .map(addPriorApprovalCoverage(priorApprovalParams));  
        }
    }

    function getRunConfigMarketsByKeys(
        keys: string[] = [],
        priorApprovalParams?: AddPriorApprovalCoverageParams
    ): RunConfigMarket[] {
        if(priorApprovalParams?.categories) {
          priorApprovalParams.categories = filterSubscribedCategories(priorApprovalParams.categories, true) as string[];
        }
        if(priorApprovalParams?.nonSummedCategories) {
          priorApprovalParams.nonSummedCategories = filterSubscribedCategories(priorApprovalParams.nonSummedCategories, true) as string[];
        }
        if (priorApprovalParams?.summedCategories) {
            priorApprovalParams.summedCategories = priorApprovalParams?.summedCategories?.reduce(
                (summedCategories: string[], currentItem) => {
                    if (currentItem === 'all') {
                        return [...summedCategories, ...filterSubscribedCategories(currentItem, true)];
                        // @ts-ignore This will ignore ts compilation error if the summedCategories is an array of objects
                    } else if (currentItem?.values && isArray(currentItem?.values)) {
                        // TODO: change type for summed selection
                        // @ts-ignore This will ignore ts compilation error if the summedCategories is an array of objects
                        return [...summedCategories, ...filterSubscribedCategories(currentItem?.values || [], true)];
                    } else {
                        return [...summedCategories, currentItem];
                    }
                },
                []
            );
        }
        return getCachedMarketNodesByKeys(keys, priorApprovalParams)
            .filter(
                (node) =>
                    !isMarketDisabled(node, {
                        requiredMarketGroup,
                        requiredMasterCompany,
                        requireRemainingMarket,
                        enableLimitedMarketSubscription,
                        accessibleMasterCompanies,
                        purchasedMarketKeys: company?.purchasedMarketKeys,
                    })
            )
            .map((node) => marketNodeToRunConfigMarket(node));
        // return getCachedMarketNodesByKeys(keys, priorApprovalParam).map((node) => marketNodeToRunConfigMarket(node));
    }

    function getRunConfigMarketForMarketName(
        marketName?: string,
        categories?: string[],
        isDodMarketPicker?: boolean,
        hasSummedCategories?: boolean,
        summedCategories?: string[] | undefined,
        nonSummedCategories?: string[],
        isMarketSummedMode?:boolean,
    ): RunConfigMarket | undefined {
        if (!marketName) return;

        let marketNode: MarketNode | undefined = getCachedMarketNodeByName(
            marketName,
            categories,
            isDodMarketPicker as boolean,
            hasSummedCategories,
            summedCategories,
            nonSummedCategories,
            isMarketSummedMode,
        );

        if (marketNode) {
            return marketNodeToRunConfigMarket(marketNode);
        } else {
            return undefined;
        }
    }

    function dedupMarkets<T extends MarketNode | RunConfigMarket>(markets?: T[]): T[] {
        return uniqBy(markets, 'name');
    }

    function enhanceAndCacheMarketNode(market: MarketNode, index): MarketNode {
        const enhancedMarket = {
            ...market,
            ...getExtraInfoForMarketNode(market),
            ...getAdditionalInfoIconsByMarketName(market.name),
        };
        allMarkets[index] = enhancedMarket; // TODO: Check this; how is name (string) being used as index for allMarkets?
        return enhancedMarket;
    }

    function validateAllMarketsHaveMatchingRemainingMarket(markets?: RunConfigMarket[]): boolean {
        if (!markets || markets.length === 0) return true;

        const includedRemainingMarketNames = markets
            .map((market) => {
                if (market.isRemaining) return market.name;
            })
            .filter(Boolean);

        return markets.reduce((result, market) => {
            if (market.isRemaining || market?.remainingMarketRunConfig?.isRemaining === true) {
                // always use true if it is a remaining market
                return result && true;
            } else {
                // see if any of the remaining markets are in the list
                const cachedMarketNode = getCachedMarketNodeByName(market.name);
                const requiredRemainingMarketNames = cachedMarketNode?.remainingMarketNames;
                // it's possible for a market to be it's own remaining market so we add it to the list
                const remainingMarketNames = [market.name, ...includedRemainingMarketNames];
                const included = Boolean(intersection(requiredRemainingMarketNames, remainingMarketNames).length);
                return result && included;
            }
        }, true);
    }

    function clearMarketCache(): void {
        // marketCache = {};
    }

    useEffect(() => {
        allMarkets.forEach(enhanceAndCacheMarketNode);
    }, [allMarkets]);

    return {
        getRunConfigMarketsByKeys,
        clearMarketCache,
        dedupMarkets,
        getCachedMarketNodeByName,
        getCachedMarketNodesByKeys,
        validateAllMarketsHaveMatchingRemainingMarket,
        findMatchingMarketOptions,
        getRootMarketNodes,
        getChildMarketNodes,
        getMarketsOptionsByMarketFilterType,
        getAccessibleRootMarketNodes,
        getRunConfigMarketForMarketName,
        filterMarketOptions,
        getAllMarkets,
        getMarketTypes,
        getMarketChannels,
        getMarketParentCompanies,
        getChildMarketTypes,
        getAllRunConfigMarkets,
        getEnabledMarkets
    };
}

type IconInfo = {
    type: string;
    message: ReactNode;
};

function getReportMarketConfig(sku: string) {
    const report = getProductBySku(sku);
    const options: MarketRunConfigOptions =
        (getRunConfigOptionsBySku(sku)?.find(({ type }) => type === 'market') as MarketRunConfigOptions) ?? {};

    const {
        includeTotalUSMarkets = false,
        includeFmcgRetailers = false,
        includeSpecialityRetailers = false,
        includeGeographyMarkets = false,
        includePanelTotal = false,
        includePanelChannel = false,
        hideTotalUSMarkets,
    } = options;

    const dataTypeForStory = report?.metadata.configOptions.filter((option) => option.type === 'market')[0].datatype;

    const datatype =
        report?.metadata.dataType === 'alert' ? 'rms' : report?.metadata.dataType ?? dataTypeForStory ?? 'rms'; // merge conflict resolution- combined logic from stories & alerts refactor

    return {
        datatype: datatype,
        includeTotalUSMarkets,
        includeFmcgRetailers,
        includeSpecialityRetailers,
        includeGeographyMarkets,
        includePanelTotal,
        includePanelChannel,
        hideTotalUSMarkets,
    };
}

/**Exception Rule - We display icons and messages based on mrkt_entitlements array coming from lookup endpoint, however below are the markets wherein we are making hasLimitedData true forcefully and showing custom message, Note - hasLimitedData is true only if causal_block === yes,but the markets written below have causal_block === null */
function getExtraInfoForMarketNode(market: MarketNode): Partial<MarketNode> | void {
    switch (market.name) {
        case 'Total FMCG Retailers':
            return {
                isPseudoRemaining: true,
            };
        case 'Total FMCG Retailers + Pet Stores':
        case 'Total Pet Stores':
        case 'Total Neighborhood Pet Retail':
            return {
                showLimitedDataTip: true,
                limitedDataType: 'pet',
                limitedDataMessage: (
                    <div className={'market-picker-node__tip-content'}>
                        Limited product scope: This market only contains data for pet-specific categories.
                        {/** BYZ_8546;support multiple warnings */}
                        {MrktEntitlementTypeMessages.TIP_LIMITED_PROMOTION}
                    </div>
                ),
            };
        case 'Total FMCG Retailers + Convenience':
        case 'Total Convenience Stores':
            return {
                showLimitedDataTip: true,
                limitedDataType: 'weight',
                limitedDataMessage: (
                    <div className={'market-picker-node__tip-content'}>
                        Limited product scope: This market does not contain data for random weight categories.
                    </div>
                ),
            };
        case 'Total FMCG Retailers + Liquor + Convenience':
        case 'Total Liquor Plus':
            return {
                showLimitedDataTip: true,
                limitedDataType: 'liquor',
                limitedDataMessage: (
                    <div className={'market-picker-node__tip-content'}>
                        Limited product scope: This market only contains data for alcohol categories.
                    </div>
                ),
            };
        case 'Amazon':
            return {
                // todo: get rid of this class.  we need a common class used to display all of our tippy content
                info: (
                    <div className={'market-picker-node__tip-content'}>
                        <p>
                            Amazon markets report POS data from Amazon physical stores including: Amazon Fresh, Amazon
                            Go, Amazon Go Grocery formats, and any sales through Amazon Fresh.com and Amazon Prime Now
                            not fulfilled by Amazon.com or Whole Foods.
                        </p>
                        <p>They do not include Amazon.com sales or any sales fulfilled through Whole Foods.com.</p>
                    </div>
                ),
            };
        case '7-Eleven Defined Geography':
            return {
                info: (
                    <div className={'market-picker-node__tip-content'}>
                        <p>
                            7-Eleven Defined Geography contains both <NoWrap>7-Eleven</NoWrap> and competitive
                            Convenience Retailers
                        </p>
                    </div>
                ),
            };
        case 'SuperValu':
            return {
                info: (
                    <div className={'market-picker-node__tip-content'}>
                        <p>
                            The UNFI Total market includes Cub and Shopper Food Warehouse, as well as independent stores
                            that UNFI supplies to and does not actually own.
                        </p>
                        <p>For details on the UNFI Supplied Independents, reach out to customer support.</p>
                    </div>
                ),
            };
    }
}

/** BYZ_7377: display info-icons to all FMCG markets and unique channels */
function getAdditionalInfoIconsByMarketName(name: string): Partial<MarketNode> | void {
    switch (name) {
        case 'Total FMCG Retailers':
            return {
                hasInfoIconForFmcgMarkets: true,
                infoMessage: (
                    <div className={'market-picker-node__tip-content'}>
                        <p>{MrktInfoIconCopy.TOTAL_FMCG_RETAILERS}</p>
                    </div>
                ),
            };
        case 'Total FMCG Retailers + Convenience':
            return {
                hasInfoIconForFmcgMarkets: true,
                infoMessage: (
                    <div className={'market-picker-node__tip-content'}>
                        <p>{MrktInfoIconCopy.TOTAL_FMCG_RETAILERS_CONVENIENCE}</p>
                    </div>
                ),
            };
        case 'Total FMCG Retailers + Liquor + Convenience':
            return {
                hasInfoIconForFmcgMarkets: true,
                infoMessage: (
                    <div className={'market-picker-node__tip-content'}>
                        <p>{MrktInfoIconCopy.TOTAL_FMCG_RETAILERS_LIQUOR_CONVENIENCE}</p>
                    </div>
                ),
            };
        case 'Total FMCG Retailers + Pet Stores':
            return {
                hasInfoIconForFmcgMarkets: true,
                infoMessage: (
                    <div className={'market-picker-node__tip-content'}>
                        <p>{MrktInfoIconCopy.TOTAL_FMCG_RETAILERS_PET_STORES}</p>
                    </div>
                ),
            };
        case 'Total Pet Stores':
            return {
                hasInfoIconForFmcgMarkets: true,
                infoMessage: (
                    <div className={'market-picker-node__tip-content'}>
                        <p>{MrktInfoIconCopy.TOTAL_PET_STORES}</p>
                    </div>
                ),
            };
        case 'Total Neighborhood Pet Retail':
            return {
                hasInfoIconForFmcgMarkets: true,
                infoMessage: (
                    <div className={'market-picker-node__tip-content'}>
                        <p>{MrktInfoIconCopy.TOTAL_NEIGHBORHOOD_PET_RETAIL}</p>
                    </div>
                ),
            };
        case 'Giant Food Total':
            return {
                hasInfoIconForFmcgMarkets: true,
                infoMessage: (
                    <div className={'market-picker-node__tip-content'}>
                        <p>{MrktInfoIconCopy.GIANT_FOOD_TOTAL}</p>
                    </div>
                ),
            };
        case 'The Giant Company':
            return {
                hasInfoIconForFmcgMarkets: true,
                infoMessage: (
                    <div className={'market-picker-node__tip-content'}>
                        <p>{MrktInfoIconCopy.THE_GIANT_COMPANY}</p>
                    </div>
                ),
            };
            case 'Alliance Retail Group Total':
            return {
                hasInfoIconForFmcgMarkets: true,
                infoMessage: (
                    <div className={'market-picker-node__tip-content'}>
                        <p>{MrktInfoIconCopy.ALLIANCE_RETAIL_GROUP_TOTAL}</p>
                    </div>
                ),
            };
    }
}

//CATEGORY_COVERAGE Logic
function addCategoryCoverage(categories?: string[]): (market: MarketNode) => MarketNode {
    // defaults to false if no categories are passed

        return (market) =>
            market.isRemaining
                ? { ...market, categoriesNotCovered: false } //BYZ-11786 If an individual retailer market is disabled due to no coverage in the selected categories, the remaining market may still be selectable
                : {
                    ...market,
                    categoriesNotCovered: categories ? !intersection(categories, market.categories).length : false,
                };
}

export function marketNodeToRunConfigMarket(node: MarketNode, subMarketType = ""): RunConfigMarket {

    return {
        path: node.path,
        key: node.key,
        name: node.name,
        selectionType: node.selectionType,
        masterCompany: node.masterCompany,
        marketGroup: node.marketGroup,
        isRemaining: node.isRemaining,
        remainingMarketRunConfig: node.remainingMarketNames,
        marketHasPartialApproval: node.hasPartialApproval,
        subMarketType: subMarketType || node.subMrktType
    };
}

//REV_SHARE Logic
function addRevShareCoverage(purchasedMarkets?: string[]): (market: MarketNode) => MarketNode {
    return (market) =>
        market.isPremium
            ? {
                  ...market,
                  purchased: purchasedMarkets?.includes(market?.key as string),
                  unPurchased: !purchasedMarkets?.includes(market?.key as string),
              }
            : { ...market };
}

function checkAccessibleMarketsByReportType(
    reportType: string,
    accessibleMasterCompanies: string[],    
    market: MarketNode,
): boolean {
    
    switch (reportType?.toLowerCase()) {
        case 'rms':
            return !accessibleMasterCompanies.includes(market.masterCompany!);
        case 'cps':  
            //TODO:Add one more check w.r.t accessiblemarkets api vs selected market.At present , I cannot use accessibleMasterCompanies fetched in the me api because the master company is always ‘NIELSEN’ for cps markets.       
            return false;
        default:
            return false;
    }
}

/**
 * This code block will handle BYZ-10500.
 * When any custom parent is selected then disable all child nodes of that parent
 * as well as other parent's child.
 * When any child market is selected inside any custom parent the disable all the parents. 
 */ 
function isMarketDisabledDueToParentCompany(market: MarketNode, selectedMarkets: RunConfigMarket[]): boolean {
    for (const selMarket of selectedMarkets) {
        if (selMarket.isParentCompany) {
            if (market.isOutletOfParentCompany) {
                return true;
            }
        } else if (selMarket.isOutletOfParentCompany) {
            if (market.isParentCompany) {
                return true;
            }
        }
    }
    return false;
}


export function isMarketDisabled(
    market: MarketNode,
    params: {
        requiredMarketGroup?: string;
        requiredMasterCompany?: string;
        requireRemainingMarket?: boolean;
        enableLimitedMarketSubscription?: boolean;
        accessibleMasterCompanies?: string[];
        reportType?:string;
        purchasedMarketKeys?: string[];
        selectedMarkets?: RunConfigMarket[];
    } = {}
): boolean {    
    const {
        requiredMarketGroup,
        requiredMasterCompany,
        requireRemainingMarket = false,
        enableLimitedMarketSubscription = false,
        accessibleMasterCompanies = [],
        reportType='rms',
        purchasedMarketKeys = [],
        selectedMarkets = []
    } = params;

    if (!market) return false;

    let isDisabledByParentCompany = isMarketDisabledDueToParentCompany(market, selectedMarkets);

    let hasNoApproval = market.hasNoApproval;
    let unPurchased = market.isPremium && !purchasedMarketKeys?.includes(market?.key as string);
    let wrongMarketGroup = Boolean(
        market.selectable && requiredMarketGroup && market.marketGroup !== requiredMarketGroup
    );
    let wrongMasterCompany = Boolean(
        market.selectable && requiredMasterCompany && market.masterCompany !== requiredMasterCompany
    );
    let noAvailableRemainingMarkets = Boolean(
        market.selectable && requireRemainingMarket && !market.remainingMarketNames?.length
    );
    let hasCategoryMismatch = Boolean(market.selectable && market.categoriesNotCovered);
    // let isMasterCompanyNotAccessible = enableLimitedMarketSubscription
    //     ? accessibleMasterCompanies.includes(market.masterCompany!)
    //         ? false
    //         : true
    //     : false;
    let isMasterCompanyNotAccessible =enableLimitedMarketSubscription?checkAccessibleMarketsByReportType(reportType!, accessibleMasterCompanies, market): false;
    
    let disabled =
        unPurchased ||
        hasNoApproval ||
        wrongMasterCompany ||
        wrongMarketGroup ||
        noAvailableRemainingMarkets ||
        hasCategoryMismatch ||
        isMasterCompanyNotAccessible ||
        isDisabledByParentCompany;

    return disabled;
}

export function isMarketHidden(
    market: MarketNode,
    params: {
        includePseudoRemainingMarkets?: boolean;
        requiredMarketGroup?: string;
        requiredMasterCompany?: string;
        requireRemainingMarket?: boolean;
        showRemainingMarkets?: boolean;
        hideDisabledMarkets?: boolean;
        hideNonPromoMarkets?: boolean;
        enableLimitedMarketSubscription?: boolean;
        accessibleMasterCompanies?: string[];
        purchasedMarketKeys?: string[];
    } = {}
): boolean {
    const {
        includePseudoRemainingMarkets,
        requiredMarketGroup,
        requiredMasterCompany,
        requireRemainingMarket,
        showRemainingMarkets,
        hideDisabledMarkets,
        hideNonPromoMarkets,
        enableLimitedMarketSubscription,
        accessibleMasterCompanies,
        purchasedMarketKeys,
    } = params;
    const hidden =
        (!showRemainingMarkets && market.isRemaining) ||
        (!includePseudoRemainingMarkets && requireRemainingMarket == true && market.isPseudoRemaining == true) ||
        (hideDisabledMarkets == true &&
            isMarketDisabled(market, {
                requiredMarketGroup,
                requiredMasterCompany,
                requireRemainingMarket,
                enableLimitedMarketSubscription,
                accessibleMasterCompanies,
                purchasedMarketKeys,
            })) ||
        (hideNonPromoMarkets == true && market.hasLimitedData == true);
    return hidden;
}
 